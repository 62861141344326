import { useContext } from 'react';
import { z } from 'zod';
import { ArrowRight } from 'lucide-react';
import { UseFormReturn, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Trans } from '@lingui/react';
import { t } from '@lingui/macro';
import { Form, FormError, FormItem, FormLabel } from 'src/ui/form';
import { Input } from 'src/ui/input';
import { Button } from '@components/ButtonV2';
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
} from 'src/ui/select';
import { UserContext } from '@lib/contexts/UserProvider';
import {
  FunnelKind,
  GenderKind,
  useCompleteProfileMutation,
} from '@gql/generated';
import AnalyticsManager from '@lib/analytics/manager';
import { cn } from '@lib/tw';
import { CountryCombobox } from '@components/form/CountryCombobox';
import { GuidedDateInput } from '@organisms/GuidedDateInput';
import { useTrackRegistration } from '@lib/hooks/useTrackRegistration';

const currentYear = new Date().getFullYear();
const maxDate = new Date();
maxDate.setFullYear(currentYear - 14);

const genderEnum = z.enum(
  [GenderKind['Male'], GenderKind['Female'], GenderKind['Other']],
  {
    required_error: t`validation.required`,
    invalid_type_error: t`register_v2.form.gender.validation_message`,
  }
);
const formSchema = z.object({
  email: z.string().email({
    message: t`validation.email`,
  }),
  firstName: z.string().min(2, {
    message: t`register_v2.form.first_name.validation_message`,
  }),
  lastName: z.string().min(2, {
    message: t`register_v2.form.last_name.validation_message`,
  }),
  //
  gender: genderEnum,
  birthday: z
    .date({
      invalid_type_error: t`validation.birthdate`,
    })
    .max(maxDate, { message: t`register_v2.form.birthday.min_age_validation` }),
  country: z.string().min(2, {
    message: t`register_v2.form.country.validation_message`,
  }),
  countryCode: z.string().optional(),
});

export function FormFields(props: {
  form: UseFormReturn<z.infer<typeof formSchema>>;
}) {
  const { form } = props;

  return (
    <div className="flex-1 max-w-2xl w-full grid auto-rows-max grid-cols-2 gap-x-5 gap-y-2 px-4 pb-4">
      <FormItem className="col-span-2">
        <FormLabel className="text-[13px]">
          <Trans id="registration.landing.email" />*
        </FormLabel>
        <Input type="email" placeholder="" {...form.register('email')} />
        <FormError>
          {form.formState?.errors?.email?.message ? (
            <Trans id={form.formState?.errors?.email?.message} />
          ) : null}
        </FormError>
      </FormItem>
      <FormItem className="">
        <FormLabel className="text-[13px]">
          <Trans id="registration.form.first_name" /> *
        </FormLabel>
        <Input placeholder="" {...form.register('firstName')} />
        <FormError>
          {form.formState?.errors?.firstName?.message ? (
            <Trans id={form.formState?.errors?.firstName?.message} />
          ) : null}
        </FormError>
      </FormItem>
      <FormItem className="">
        <FormLabel className="text-[13px]">
          <Trans id="registration.form.last_name" />*
        </FormLabel>
        <Input placeholder="" {...form.register('lastName')} />
        <FormError>
          {form.formState?.errors?.lastName?.message ? (
            <Trans id={form.formState?.errors?.lastName?.message} />
          ) : null}
        </FormError>
      </FormItem>
      <FormItem className="col-span-2">
        <FormLabel className="text-[13px]">
          <Trans id="registration.landing.gender" />*
        </FormLabel>
        <Select
          defaultValue={form.getValues('gender')}
          onValueChange={(value) => {
            form.setValue('gender', genderEnum.parse(value));
            form.trigger('gender');
          }}
        >
          <SelectTrigger
            className="w-full gap-1 justify-between [&>svg]:opacity-1 border-border"
            name="gender"
          >
            <SelectValue />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value={GenderKind['Female']}>
              <Trans id="metadata.gender.female" />
            </SelectItem>
            <SelectItem value={GenderKind['Male']}>
              <Trans id="metadata.gender.male" />
            </SelectItem>
            <SelectItem value={GenderKind['Other']}>
              <Trans id="metadata.gender.other" />
            </SelectItem>
          </SelectContent>
        </Select>
        <FormError>
          {form.formState?.errors?.gender?.message ? (
            <Trans id={form.formState?.errors?.gender?.message} />
          ) : null}
        </FormError>
      </FormItem>
      <FormItem className="flex flex-col col-span-2">
        <FormLabel className="text-[13px]">
          <Trans id="registration.landing.birthday" />*
        </FormLabel>
        <GuidedDateInput
          className="w-full"
          onChange={(date) => {
            form.setValue('birthday', date);
            form.trigger('birthday');
          }}
        />
        <FormError>
          {form.formState.isSubmitted &&
          form.formState?.errors?.birthday?.message ? (
            <Trans id={form.formState?.errors?.birthday?.message} />
          ) : null}
        </FormError>
      </FormItem>
      <FormItem className="col-span-2">
        <FormLabel className="text-[13px]">
          <Trans id="registration.landing.country" />*
        </FormLabel>
        <CountryCombobox
          defaultSelectedCountryCode={form.getValues('countryCode')}
          onChange={(country) => {
            form.setValue('country', country.label);
            form.setValue('countryCode', country.code);
            form.trigger('country');
          }}
        />
        <FormError>
          {form.formState?.errors?.country?.message ? (
            <Trans id={form.formState?.errors?.country?.message} />
          ) : null}
        </FormError>
      </FormItem>
    </div>
  );
}

type Props = {
  className?: string;
  onComplete(): void;
  logout?: boolean;
};

const PROFILE_COMPLETE_ERROR = 'Profile is already completed';
export const RegisterV2CompleteProfile = (props: Props) => {
  const trackStep = useTrackRegistration();

  const [completeProfile, { data, error, loading }] =
    useCompleteProfileMutation({
      onError(error) {
        if (error.message === PROFILE_COMPLETE_ERROR) {
          props.onComplete();
        }
      },
    });

  const [currentUser, { refetch }] = useContext(UserContext);
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: currentUser?.email,
      firstName: currentUser?.firstName || '',
      lastName: currentUser?.lastName || '',
      gender: GenderKind['Female'],
      birthday: null,
      country: currentUser.country,
      countryCode: currentUser.countryCode,
    },
  });

  async function onSubmit(values) {
    try {
      const resp = await completeProfile({
        variables: {
          ...values,
        },
      });
      const [user] = await refetch({ onlyBaseProperties: true });

      if (resp.data?.completeProfile?.user?.id) {
        AnalyticsManager().sendProfileCompletedEvent({
          funnel:
            user[0]?.data?.currentUser?.finalFunnel ||
            user[0]?.data?.currentUser?.funnel,
        });

        if (user.data.currentUser.finalFunnel.startsWith('play_trial')) {
          trackStep('play_trial_profile_completed');
        } else if (
          user.data.currentUser.finalFunnel.startsWith('play_subscription')
        ) {
          trackStep('play_subscription_profile_completed');
        } else if (
          user.data.currentUser.finalFunnel === FunnelKind['WellnessTrial']
        ) {
          trackStep('play_wellness_profile_completed');
        }
        props.onComplete();
      }
    } catch (ex) {
      //
    }
  }

  return (
    <div
      className={cn('flex-1 size-full flex flex-col', props.className)}
      data-testid="CompleteProfile"
    >
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
          className="flex-1 flex flex-col items-center"
          aria-labelledby="form-title"
        >
          <div className="max-w-2xl w-full xl:pt-4 pb-6 mx-auto">
            <h2 id="form-title" className="text-2xl text-dark px-4 mx-0 my-0">
              <Trans id="register_v2.get_started.complete_profile.form_title" />
            </h2>
            <p className="text-sm md:text-base text-dark font-medium px-4 py-4 mx-0 my-0">
              <Trans id="register_v2.get_started.complete_profile.form_desc" />
            </p>
          </div>
          <FormFields form={form} />
          {data?.completeProfile?.errors?.length > 0 ||
          ![undefined, null, PROFILE_COMPLETE_ERROR].includes(
            error?.message
          ) ? (
            <p className="text-red-500">
              {data?.completeProfile?.errors[0]?.message || error?.message}
            </p>
          ) : null}
          <div className="sticky bottom-0 items-center flex flex-col gap-5 w-full bg-white px-4 py-4 border-t border-t-neutral-300">
            <Button
              type="submit"
              className="max-w-2xl w-full justify-center mx-auto"
              loading={loading}
              data-testid="CompleteProfile_submit"
              rounded
            >
              <Trans id="register_v2.get_started.complete_profile.form_btn" />{' '}
              <ArrowRight className="text-inherit size-4 stroke-1 ml-2" />
            </Button>
            {props.logout ? (
              <a
                className="flex justify-center font-semibold text-primary w-auto"
                href={`/logout`}
              >
                <Trans id="register_v2.get_started.complete_profile.logout" />
              </a>
            ) : null}
          </div>
        </form>
      </Form>
    </div>
  );
};
