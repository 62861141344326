import { useContext, useState } from 'react';
import * as Sentry from '@sentry/nextjs';
import { UserContext } from '@lib/contexts/UserProvider';
import {
  onAdditionalPaymentDetails,
  onSubmit,
} from '@organisms/modals/payment/AdyenFormModal';
import {
  AddAdyenPaymentMethodMutation,
  AdyenResultCodeKind,
  PaymentResultCodeKind,
  PaymentsStorePaymentDetailsMutation,
  RecurringModelInputKind,
  useAddAdyenPaymentMethodMutation,
  useAddTrustlyPaymentMethodMutation,
  usePaymentsStorePaymentDetailsMutation,
} from '@gql/generated';

type Props = {
  onPaymentMethodAdded(
    payload: AddAdyenPaymentMethodMutation['addAdyenPaymentMethod']
  ): void;
  onPaymentDetailsAdded(
    data: PaymentsStorePaymentDetailsMutation['paymentsStorePaymentDetails']
  ): void;
  redirectTo?: string;
  recurringModel?: RecurringModelInputKind;
};

export const useAddPaymentMethod = (props: Props) => {
  const {
    onPaymentMethodAdded,
    onPaymentDetailsAdded,
    redirectTo,
    recurringModel = RecurringModelInputKind['CardOnFile'],
  } = props;
  const [currentUser] = useContext(UserContext);
  const [paymentError, setPaymentError] = useState(false);
  const [addTrustlyMethod, { loading: trustlyLoading, error: trustlyError }] =
    useAddTrustlyPaymentMethodMutation();
  const [
    storePaymentDetails,
    { loading: storePaymentDetailsLoading, error: storePaymentDetailsError },
  ] = usePaymentsStorePaymentDetailsMutation();
  const [addAdyenMethod, { loading: adyenLoading, error: adyenError }] =
    useAddAdyenPaymentMethodMutation();

  const handlePaymentSubmit: onSubmit = async (
    variables,
    instance,
    actions
  ) => {
    setPaymentError(false);

    try {
      if (variables.paymentMethod.providerType === 'trustly') {
        const resp = await addTrustlyMethod({
          variables: {
            redirectTo,
          },
        });
        if (resp.data.addTrustlyPaymentMethod.resultUrl) {
          window.location.href = resp.data.addTrustlyPaymentMethod.resultUrl;
        }
      } else if (variables.paymentMethod.providerType === 'scheme') {
        const resp = await addAdyenMethod({
          variables: {
            ...variables,
            recurringModel,
            redirectToSuccess: redirectTo,
            redirectToFailed: redirectTo,
          },
        });

        // handle exception
        if (!resp?.data) {
          setPaymentError(true);
          return null;
        }

        const resultCode = resp.data?.addAdyenPaymentMethod?.resultCode;

        if (
          resp?.data?.addAdyenPaymentMethod?.errors?.length > 0 &&
          !resultCode
        ) {
          setPaymentError(true);
          Sentry.captureEvent({
            message: 'Failed add payment method',
            contexts: {
              user: {
                id: currentUser?.id,
              },
              response: {
                ...resp?.data?.addAdyenPaymentMethod,
                errors: resp?.data?.addAdyenPaymentMethod?.errors[0],
              },
            },
          });
        } else {
          const resultAction = resp.data?.addAdyenPaymentMethod?.resultAction;
          if (['threeDS2', 'qrCode', 'redirect'].includes(resultAction?.type)) {
            instance['_order_id'] = resp.data?.addAdyenPaymentMethod?.orderId;

            // actions.resolve({
            //   resultCode: 'IdentifyShopper',
            //   action: {
            //     paymentData: resultAction.paymentData,
            //     paymentMethodType: resultAction.paymentMethodType,
            //     type: resultAction.type,
            //     authorisationToken: resultAction.authorisationToken,
            //     subtype: resultAction.subtype,
            //     token: resultAction.token,
            //   },
            // });

            instance.handleAction({
              paymentData: resultAction.paymentData,
              paymentMethodType: resultAction.paymentMethodType,
              type: resultAction.type,
              authorisationToken: resultAction.authorisationToken,
              subtype: resultAction.subtype,
              token: resultAction.token,
            });
          } else if (resultCode === PaymentResultCodeKind['Refused']) {
            setPaymentError(true);
            instance.update({});
          } else if (
            [
              PaymentResultCodeKind['Authorised'],
              PaymentResultCodeKind['Pending'],
            ].includes(resultCode)
          ) {
            onPaymentMethodAdded(resp?.data?.addAdyenPaymentMethod);
          }
        }
      }

      return null;
    } catch (ex) {
      //
      console.error({ ex });
      setPaymentError(true);
    }
  };

  const handleAdditionalPaymentDetails: onAdditionalPaymentDetails = async (
    response,
    instance
  ) => {
    const resp = await storePaymentDetails({
      variables: {
        threedsResult: response?.data?.details?.threeDSResult,
        payload: response?.data?.details?.payload,
        paymentData: response?.data?.paymentData,
        orderId: instance['_order_id'],
      },
    });

    const resultCode = resp?.data?.paymentsStorePaymentDetails?.resultCode;
    if (resultCode === AdyenResultCodeKind['Refused']) {
      setPaymentError(true);
      instance.update({});
    } else if (
      [
        AdyenResultCodeKind['Authorised'],
        AdyenResultCodeKind['Pending'],
      ].includes(resultCode)
    ) {
      onPaymentDetailsAdded(resp.data?.paymentsStorePaymentDetails);
    } else if (AdyenResultCodeKind['Cancelled'] === resultCode) {
      instance.update({});
    }

    instance['_order_id'] = null;
  };

  return {
    addMethod: handlePaymentSubmit,
    handleAdditionalPaymentDetails,
    loading: trustlyLoading || adyenLoading || storePaymentDetailsLoading,
    error: !!(
      trustlyError ||
      adyenError ||
      paymentError ||
      storePaymentDetailsError
    ),
  };
};
