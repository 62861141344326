import React, { useContext } from 'react';
import styled from 'styled-components';
import Link from 'next/link';
import { isFuture } from 'date-fns';
import { useRouter } from 'next/router';
import useFlags from '@lib/hooks/useFlags';
import messageStripe from '@components/MessageStripe';
import LanguageContext from '@lib/contexts/languageContext';
import { UserContext } from '@lib/contexts/UserProvider';
import { isEnabledOnPage } from '@lib/utils';

const MessageStripe = styled(messageStripe)``;

const TEXTS = {
  first:
    '📣 JUST NU! Två exklusiva paketerbjudanden: 12 mån Play + unik bonus (spara 799 SEK) Vilket paket väljer du?',
  last: 'SISTA CHANSEN 🎁 Köp 12 månader Yogobe Play och få en unik bonus värde 499 SEK!',
};

const start_date = new Date(Date.UTC(2025, 1, 24, 23));
const end_date = new Date(Date.UTC(2025, 3, 10, 23));

const YearlyOffer = ({}) => {
  const router = useRouter();
  const [flags] = useFlags();
  const lang = useContext(LanguageContext);
  const [currentUser] = useContext(UserContext);

  const ENABLED_PATHS = [
    { path: '/[lang]/blog', exact: false },
    { path: '/[lang]/marketplace', exact: false },
    { path: '/[lang]/videos', exact: false },
    { path: '/[lang]/playlists', exact: false },
    { path: '/[lang]/programs', exact: true },
    { path: '/[lang]/courses', exact: true },
    { path: '/[lang]/needs-and-themes', exact: true },
    {
      path: '/[lang]/c/[client]/b/[slug]',
      exact: true,
      validate() {
        return router.query.client === 'yogobe';
      },
    },
  ];

  let showYearlyCampaign = false;

  if (!currentUser?.isPremium) {
    showYearlyCampaign = true;
  }

  const allowed_on_page =
    lang === 'se' && isEnabledOnPage(ENABLED_PATHS, router.pathname);

  if (
    !allowed_on_page ||
    !showYearlyCampaign ||
    !flags?.yearly_campaign ||
    isFuture(start_date) ||
    !isFuture(end_date)
  ) {
    return null;
  }

  const text = isFuture(new Date(Date.UTC(2025, 3, 9, 23)))
    ? TEXTS['first']
    : TEXTS['last'];

  const isPricingPage = router?.pathname.startsWith('/[lang]/pricing');

  const bgColor = isPricingPage ? '#119edd' : '#D9E8E7';
  const color = isPricingPage ? 'white' : '#242424';

  return (
    <MessageStripe bg={bgColor} color={color}>
      <Link href={`/${lang}/yearly`}>{text}</Link>
    </MessageStripe>
  );
};

export default YearlyOffer;
