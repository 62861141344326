import React, { useContext, useEffect } from 'react';
import styled, { useTheme } from 'styled-components';
import { debounce } from 'lodash';
import { Trans } from '@lingui/react';
import LanguageContext from '@lib/contexts/languageContext';
import MenuContent, {
  Title,
  Description,
  Anchor,
  Items,
  Element,
  Content,
} from './MenuContent';
// import { WellnessBanner } from './MoreMenu';
import { findParentElement } from '@lib/utils';
import { cn } from '@lib/tw';

const Menu = styled(MenuContent)`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${Content} {
    padding-top: 0;
    padding-bottom: 0;
  }

  ${Element}:nth-of-type(4) {
    display: none;
  }

  @media (min-width: ${({ theme }) => theme.viewports.laptop}px) {
    width: 100vw;
    border-top-right-radius: 0;
    border-top-left-radius: 0;

    ${Element}:not(:nth-of-type(4)) {
      padding: 32px 0;
    }

    ${Element}:nth-of-type(4) {
      display: flex;
      margin: 0;
      max-width: 380px;
    }

    ${Items} {
      max-width: 1280px;
      width: 100%;
      grid-template-columns: repeat(3, minmax(0, 1fr));
    }
  }

  @media (min-width: ${({ theme }) => theme.viewports.desktop}px) {
    ${Items} {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
  }
`;

const text = {
  overview: {
    title: <Trans id="global_menu.play.overview.title" />,
    desc: <Trans id="global_menu.play.overview.desc" />,
  },
  video_audio: {
    title: <Trans id="global_menu.play.video_audio.title" />,
    desc: <Trans id="global_menu.play.video_audio.desc" />,
  },
  playlists: {
    title: <Trans id="global_menu.play.playlists.title" />,
    desc: <Trans id="global_menu.play.playlists.desc" />,
  },
  programs: {
    title: <Trans id="global_menu.play.programs.title" />,
    desc: <Trans id="global_menu.play.programs.desc" />,
  },
  yogobe_challenge: {
    title: <Trans id="global_menu.play.yogobe_challenge.title" />,
    desc: <Trans id="global_menu.play.yogobe_challenge.desc" />,
  },
  live: {
    title: <Trans id="global_menu.play.live.title" />,
    desc: <Trans id="global_menu.play.live.desc" />,
  },
  bundles: {
    title: <Trans id="global_menu.play.needs_and_themes.title" />,
    desc: <Trans id="global_menu.play.needs_and_themes.desc" />,
  },
};

export const WellnessBanner = ({ className = '' }) => {
  const lang = useContext(LanguageContext);

  return (
    <div
      className={cn(
        'flex flex-col justify-center relative bg-[#eee] rounded lg:rounded-none',
        className
      )}
    >
      <div className="hidden lg:block absolute w-[100vw] left-0 top-0 h-full bg-[#eee] -z-1"></div>
      <a
        href={`/${lang}/friskvardsbidrag`}
        className="relative z-10 block  max-w-xl lg:max-w-xs text-dark p-5 lg:pr-0 lg:py-8 lg:pl-8"
      >
        <img
          className="w-full object-cover rounded aspect-[2.22]"
          src="/static/play-menu-wellness.webp"
          alt=""
        />
        <div className="py-2">
          <p className="text-base font-medium m-0 pb-1">Friskvårdsbidrag</p>
          <p className="font-light text-sm m-0">
            Med Yogobe Flex kan du använda hela friskvårdsbidraget – till sista
            kronan!
          </p>
        </div>
        <span className="underline text-sm font-light">Läs mer</span>
      </a>
    </div>
  );
};

const PlayMenu = ({ className = '', open = false }) => {
  const ref = React.createRef<HTMLDivElement>();
  const lang = useContext(LanguageContext);
  const theme = useTheme();

  const positionMenu = () => {
    const banner = ref.current;
    const li = findParentElement(banner, 'li');
    const { x } = li.getBoundingClientRect();
    banner.style.left = -x + 'px';
  };

  useEffect(() => {
    if (window.matchMedia(`(${theme.viewports.tablet}px)`)) {
      setTimeout(() => {
        positionMenu();
      }, 1000);

      const resizeHandler = debounce(
        () => {
          positionMenu();
        },
        400,
        { maxWait: 1000 }
      );

      window.addEventListener('resize', resizeHandler);
      return () => window.removeEventListener('resize', resizeHandler);
    }
  }, []);

  const items = [
    [
      <Anchor key="1" href={`/${lang}/videos/overview`} prefetch={false}>
        <Title>{text.overview.title}</Title>
        <Description>{text.overview.desc}</Description>
      </Anchor>,
      <Anchor key="2" href={`/${lang}/programs`} prefetch={false}>
        <Title>{text.programs.title}</Title>
        <Description>{text.programs.desc}</Description>
      </Anchor>,
      <Anchor key="3" href={`/${lang}/playlists`} prefetch={false}>
        <Title>{text.playlists.title}</Title>
        <Description>{text.playlists.desc}</Description>
      </Anchor>,
    ],
    [
      <Anchor key="4" href={`/${lang}/videos/genres/all`} prefetch={false}>
        <Title>{text.video_audio.title}</Title>
        <Description>{text.video_audio.desc}</Description>
      </Anchor>,
      <Anchor key="5" href={`/${lang}/challenges`} prefetch={false}>
        <Title>{text.yogobe_challenge.title}</Title>
        <Description>{text.yogobe_challenge.desc}</Description>
      </Anchor>,
    ],
    [
      <Anchor key="6" href={`/${lang}/live?kind=classe`} prefetch={false}>
        <Title>{text.live.title}</Title>
        <Description>{text.live.desc}</Description>
      </Anchor>,
      <Anchor key="7" href={`/${lang}/needs-and-themes`} prefetch={false}>
        <Title>{text.bundles.title}</Title>
        <Description>{text.bundles.desc}</Description>
      </Anchor>,
    ],
  ];

  if (['se', 'fi'].includes(lang)) {
    items.push([<WellnessBanner key="9" />]);
  }

  return <Menu ref={ref} className={className} open={open} items={items} />;
};

export default PlayMenu;
