import React, {
  useRef,
  useState,
  useEffect,
  useContext,
  useCallback,
} from 'react';
import styled from 'styled-components';
import { useRouter } from 'next/router';
import Link from 'next/link';
import Divider from '@components/Divider';
import LanguageContext from '@lib/contexts/languageContext';
import { Trans } from '@lingui/react';
import { Button, Login } from './subcomponents';
import useFlags from '@lib/hooks/useFlags';
import { UserContext } from '@lib/contexts/UserProvider';
import useLoginModal from '@lib/hooks/useLoginModal';
import { ChevronDown } from 'lucide-react';
import { cn } from '@lib/tw';

const Menu = styled.div<{ open: boolean }>`
  position: absolute;
  top: 100%;
  right: 20px;
  display: flex;
  flex-direction: column;
  text-align: left;
  background-color: white;
  box-shadow:
    0px 5px 5px -3px rgb(0 0 0 / 20%),
    0px 8px 10px 1px rgb(0 0 0 / 14%),
    0px 3px 14px 2px rgb(0 0 0 / 12%);
  opacity: 0;
  z-index: -1;
  pointer-events: none;
  transform: scale(0.7);
  transform-origin: top right;
  transition:
    opacity 331ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    transform 220ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  ${({ open }) => {
    return open
      ? `
        z-index: 999;
        opacity: 1;
        pointer-events: initial;
        transform: scale(1);
      `
      : '';
  }};
`;

const MenuItem = styled(Link)`
  min-width: 230px;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.neutrals.black};
  font-size: 14px;
  line-height: 1;
  text-decoration: none;
  padding: 20px 30px;

  :hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
`;

const HeaderMenuItem = styled(MenuItem)`
  justify-content: space-between;
  padding: 20px 30px;
  background-color: white;
  cursor: pointer;
`;

const FooterMenuItem = styled(HeaderMenuItem)`
  padding: 18px 30px;
`;

const NewTag = styled.span`
  font-size: 11px;
  color: #fff;
  font-weight: 500;
  border-radius: 3px;
  padding: 5px;
  margin-left: 8px;
  background-color: rgb(18, 158, 220);
`;

const MenuDropdown = ({ locale, onItemClick }) => {
  const [flags, { loading }] = useFlags();

  const links = [
    {
      link: `/${locale}/me/dashboard`,
      text: <Trans id="userlogin.my_dashboard" />,
    },
    {
      link: `/${locale}/me/favorites/videos`,
      text: <Trans id="userlogin.my_favorites" />,
    },
    {
      link: `/${locale}/me/playlists`,
      text: <Trans id="userlogin.my_playlists" />,
    },
    {
      link: `/${locale}/me/settings`,
      text: <Trans id="userlogin.settings" />,
    },
    // {
    //   link: `/${locale}/me/settings/subscriptions`,
    //   text: <Trans id="userlogin.my_subscription" />,
    // },
  ];

  if (!flags.disable_dashboard_stats && !loading) {
    links.push({
      link: `/${locale}/me/statistics`,
      text: (
        <>
          <Trans id="userlogin.my_stats" />
          <NewTag>New</NewTag>
        </>
      ),
    });
  }

  return (
    <>
      {links.map((item, i) => {
        return (
          <MenuItem
            key={i}
            href={item.link}
            onClick={onItemClick}
            prefetch={false}
          >
            {item.text}
          </MenuItem>
        );
      })}
    </>
  );
};

const LoggedUser = () => {
  const locale = useContext(LanguageContext);
  const anchorEl = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  const [currentUser] = useContext(UserContext);

  const handleClick = () => {
    setOpen(!open);
  };

  const handleGlobalClick = useCallback(
    (e: MouseEvent) => {
      setOpen((open) => {
        if (open && !anchorEl.current.contains(e.target as HTMLElement)) {
          return false;
        }

        return open;
      });
    },
    [open, anchorEl.current]
  );

  useEffect(() => {
    window.addEventListener('click', handleGlobalClick);

    return () => {
      setOpen(false);
      window.removeEventListener('click', handleGlobalClick);
    };
  }, []);

  return (
    <div ref={anchorEl}>
      <Login.AvatarWrapper onClick={handleClick}>
        {currentUser ? (
          <span className="Avatar--Name">
            {currentUser.firstName
              ? currentUser.firstName
              : currentUser.profile?.displayName}
          </span>
        ) : null}
        <Login.Avatar
          src={
            currentUser?.assets?.avatar?.includes?.('missing.png')
              ? '/static/profile-placeholder.svg'
              : currentUser?.assets?.avatar
          }
          // onError={(e) => {
          //   console.log('e', e);
          //   if (
          //     e.target instanceof HTMLImageElement &&
          //     e.target.src !== '/static/profile-placeholder.svg'
          //   ) {
          //     e.target.src = '/static/profile-placeholder.svg';
          //   }
          // }}
        />
        <ChevronDown
          className={cn(
            'size-5 text-primary transition-transform duration-300',
            open ? '[transform:rotateX(180deg)]' : ''
          )}
        />
      </Login.AvatarWrapper>
      <Menu open={open}>
        <MenuDropdown locale={locale} onItemClick={handleClick} />
        <Divider />
        <FooterMenuItem
          href="/logout"
          onClick={(e) => {
            e.preventDefault();
            window.location.href = '/logout';
          }}
        >
          <Trans id="userlogin.logout" message="Log out" />
        </FooterMenuItem>
      </Menu>
    </div>
  );
};

const GuestView = () => {
  const router = useRouter();
  const locale = useContext(LanguageContext);
  const [modal, { openModal: openLoginModal }] = useLoginModal({
    onClose() {
      if (router.query.to || router.query.action) {
        const { action, to, lang, ...query } = router.query;

        const asPath = router.asPath.split('?')[0];
        setTimeout(() => {
          router.replace(router.pathname, {
            pathname: asPath,
            query,
          });
        });
      }
    },
  });

  useEffect(() => {
    if (router.query.action === 'login') {
      openLoginModal();
    }
    // we abserve query.action because in test mode we do frontend redirect
  }, [router.query.action]);

  return (
    <>
      <Login.Wrapper>
        <Button
          className="loginCTA"
          href={{
            pathname: `/${locale}/login`,
            query: {
              to: router?.asPath,
            },
          }}
          prefetch={false}
          appearance="dark"
          link
          size="small"
          onClick={(e) => {
            e.preventDefault();
            openLoginModal();
          }}
        >
          <Trans id="header.login" message="login" />
        </Button>
        <Button
          className=""
          href={{
            pathname: `/${locale}/register/account`,
            query: {
              ...(router?.query?.code ? { code: router?.query?.code } : {}),
            },
          }}
          prefetch={false}
          appearance="default-blue"
          size="small"
        >
          <Trans id="header.try_free" message="Try for free" />
        </Button>
      </Login.Wrapper>
      {modal}
    </>
  );
};

const UserSpace = () => {
  const [currentUser] = useContext(UserContext);

  return currentUser?.id ? <LoggedUser /> : <GuestView />;
};

export default UserSpace;
