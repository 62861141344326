export const GA4_TRACKING_ID = process.env.GA4_TRACKING_ID;
export const GA_TRACKING_ID = process.env.GA_TRACKING_ID;
export const GTM_ID = process.env.GTM_ID;

type EventParams = {
  action: string;
  category: string;
  label: string;
  value: number;
  currency?: string;
};

class GoogleAnalyticsManager {
  currentUser: Record<string, any>;

  constructor() {}

  setUserProperties(currentUser) {
    if (currentUser) {
      this.currentUser = currentUser;
      this.setGAUser(currentUser);
    }
  }

  setGAUser(user) {
    if (window.gtag) {
      window.gtag('set', user);
    }
  }

  login(data = {}) {
    this.sendEvent('login', data);
  }

  registration_begin() {
    this.sendEvent('registration_begin', {});
    window.dataLayer &&
      window.dataLayer?.push({
        event: 'registration_begin',
      });
  }
  registration_step1Complete({ registration_source }) {
    this.sendEvent('registration_step1_complete', {
      registration_source,
    });
    window.dataLayer &&
      window.dataLayer?.push({
        event: 'registration_step1_complete',
        registration_source,
      });
  }

  registration_step2Complete() {
    this.sendEvent('registration_step2_complete', {});
    window.dataLayer &&
      window.dataLayer?.push({
        event: 'registration_step2_complete',
      });
  }

  registration_campaignCodeUsed(data) {
    this.sendEvent('registration_campaign-code_used', data);
  }

  registration_trialPaymentMethodAdded(data) {
    this.sendEvent('registration_payment_method_added', data);
  }

  trial_started({ transaction_id, plan, value, currency }) {
    this.sendEvent('trial_started', {
      plan,
      value,
      currency,
    });

    if (!window.dataLayer) {
      window.dataLayer = [];
    }

    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: 'free_trial',
      status: this.currentUser ? 'logged_in' : 'logged_out',
      ecommerce: {
        transaction_id,
        currency,
        value: 0,
        items: [
          {
            item_id: plan,
            item_name: 'Free Trial',
            item_category: 'Subscription',
            value: 0,
            quantity: 1,
          },
        ],
      },
    });
  }

  trial_plan_selected({ plan, amount = null, currency = null }) {
    this.sendEvent('trial_plan_selected', {
      plan,
      amount,
      currency,
    });
  }

  //
  book_event(data) {
    this.sendEvent('book_event', data);
    window.dataLayer &&
      window.dataLayer?.push({
        ...data,
        event: 'book_event',
      });
  }

  program_begin(data) {
    this.sendEvent('program_begin', data);
  }

  program_complete(data) {
    this.sendEvent('program_complete', data);
  }

  challenge_begin(data) {
    this.sendEvent('challenge_begin', data);
  }

  challenge_complete(data) {
    this.sendEvent('challenge_complete', data);
  }

  course_begin(data) {
    this.sendEvent('course_begin', data);
  }

  course_complete(data) {
    this.sendEvent('course_complete', data);
  }

  bundle_preRegister(data) {
    this.sendEvent('bundle_pre-register', data);
  }

  bundle_begin(data) {
    this.sendEvent('bundle_begin', data);
  }

  recommendations_begin() {
    this.sendEvent('recommendations_begin', {});
  }

  recommendations_complete() {
    this.sendEvent('recommendations_complete', {});
  }

  addto_playlist(data) {
    this.sendEvent('addto_playlist', data);
  }

  favorite_session(data) {
    this.sendEvent('favorite_session', data);
  }

  favorite_playlist(data) {
    this.sendEvent('favorite_playlist', data);
  }

  search_global(data) {
    this.sendEvent('search_global', data);
  }

  search_library(data) {
    this.sendEvent('search_library', data);
  }

  //
  purchase_subscriptionBegin(data) {
    this.sendEvent('purchase_subscription_begin', data);

    if (!window.dataLayer) {
      window.dataLayer = [];
    }

    //
    // window.dataLayer?.push({
    //   ...data,
    //   event: 'purchase_subscription_begin',
    // });

    //
    window.dataLayer?.push({ ecommerce: null });
    window.dataLayer?.push({
      event: 'begin_checkout',
      status: 'logged_in',
      new_customer: data.new_customer,
      ecommerce: {
        value: data.value,
        currency: data.currency,
        items: [
          {
            item_id: data.plan,
            item_name: data.payment_type || data.plan,
            item_category: 'Subscription',
            value: data.value,
            currency: data.currency,
            quantity: 1,
          },
        ],
      },
    });
  }

  purchase_subscriptionComplete(data: {
    order_id: string;
    months: number;
    value: number;
    original_amount: number;
    currency: string;
    payment_type: string;
    source_page: string;
    new_customer: boolean;
    wellness?: boolean;
    wellness_provider?: string;
  }) {
    const PLANS = { 1: 'monthly', 3: 'quarterly', 12: 'yearly' };
    this.sendEvent('purchase_subscription_complete', data);
    if (!window.dataLayer) {
      window.dataLayer = [];
    }
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: 'purchase',
      new_customer: data.new_customer,
      status: this.currentUser ? 'logged_in' : 'logged_out',
      ecommerce: {
        transaction_id: data.order_id,
        value: data.value,
        currency: data.currency,
        items: [
          {
            index: 0,
            item_id: PLANS[data.months],
            item_name: data.payment_type || PLANS[data.months],
            item_category: 'Subscription',
            is_wellness: data.wellness,
            value: data.value,
            currency: data.currency,
            quantity: 1,
          },
        ],
      },
    });
  }
  purchase_subscriptionFail(data) {
    this.sendEvent('purchase_subscription_fail', data);
  }

  //
  purchase_LiveBegin(data) {
    this.sendEvent('purchase_live_begin', data);
    if (!window.dataLayer) {
      window.dataLayer = [];
    }
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer?.push({
      event: 'begin_checkout',
      status: 'logged_in',
      ecommerce: {
        value: data.value,
        currency: data.currency,
        items: [
          {
            index: 0,
            item_id: data.slug,
            item_name: data.title,
            item_category: 'LiveEvent',
            value: data.value,
            currency: data.currency,
            quantity: 1,
          },
        ],
      },
    });
    // window.dataLayer?.push({
    //   ...data,
    //   event: 'purchase_live_begin',
    // });
  }

  purchase_LiveComplete(data: {
    order_id: string;
    id: string;
    title: string;
    slug: string;
    value: number;
    currency: string;
    event_theme: string;
    instructor_name: string;
    new_customer: boolean;
  }) {
    this.sendEvent('purchase_live_complete', data);
    if (!window.dataLayer) {
      window.dataLayer = [];
    }
    window.dataLayer.push({ ecommerce: null });
    // @ts-ignore
    window.dataLayer?.push({
      event: 'purchase',
      new_customer: data.new_customer,
      status: this.currentUser ? 'logged_in' : 'logged_out',
      ecommerce: {
        transaction_id: data.order_id,
        value: data.value,
        currency: data.currency,
        items: [
          {
            index: 0,
            item_id: data.slug,
            item_name: data.title,
            item_category: 'LiveEvent',
            value: data.value,
            currency: data.currency,
            quantity: 1,
          },
        ],
      },
    });
  }

  purchase_LiveVoucherComplete(data: {
    id: string;
    order_id: string;
    title: string;
    slug: string;
    value: number;
    currency: string;
    new_customer: boolean;
  }) {
    this.sendEvent('purchase_live_voucher_complete', data);
    if (!window.dataLayer) {
      window.dataLayer = [];
    }
    window.dataLayer.push({ ecommerce: null });
    // @ts-ignore
    window.dataLayer?.push({
      event: 'purchase',
      new_customer: data.new_customer,
      status: this.currentUser ? 'logged_in' : 'logged_out',
      ecommerce: {
        transaction_id: data.order_id,
        value: data.value,
        currency: data.currency,
        items: [
          {
            index: 0,
            item_id: data.slug,
            item_name: data.title,
            item_category: 'LiveEvent',
            value: data.value,
            currency: data.currency,
            is_voucher: true,
            quantity: 1,
          },
        ],
      },
    });
  }

  purchase_LiveFail(data) {
    this.sendEvent('purchase_live_fail', data);
  }

  //
  purchase_courseBegin(data) {
    this.sendEvent('purchase_course_begin', data);
    if (!window.dataLayer) {
      window.dataLayer = [];
    }

    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: 'begin_checkout',
      status: 'logged_in',
      new_customer: data.new_customer,
      ecommerce: {
        value: data.value,
        currency: data.currency,
        items: [
          {
            index: 0,
            item_id: data.slug,
            item_name: data.title,
            item_category: 'Course',
            value: data.value,
            currency: data.currency,
            quantity: 1,
          },
        ],
      },
    });
  }
  purchase_courseComplete(data: {
    order_id: string;
    item_type: string;
    id: string;
    title: string;
    slug: string;
    value: number;
    original_amount: number;
    currency: string;
    new_customer: boolean;
    payment_type?: string;
    voucher_purchase?: boolean;
  }) {
    this.sendEvent('purchase_course_complete', data);
    if (!window.dataLayer) {
      window.dataLayer = [];
    }
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: 'purchase',
      new_customer: data.new_customer,
      status: this.currentUser ? 'logged_in' : 'logged_out',
      ecommerce: {
        transaction_id: data.order_id,
        value: data.value,
        currency: data.currency,
        items: [
          {
            index: 0,
            item_id: data.slug,
            item_name: data.title,
            item_category: 'Course',
            value: data.value,
            currency: data.currency,
            is_voucher: data.voucher_purchase,
            quantity: 1,
          },
        ],
      },
    });
  }
  purchase_courseFail(data) {
    this.sendEvent('purchase_course_fail', data);
  }

  // custom access
  purchase_CustomAccessBegin(data: {
    name: string;
    value: number;
    currency: string;
    access_days: number;
  }) {
    this.sendEvent('purchase_custom_access_begin', data);
    if (!window.dataLayer) {
      window.dataLayer = [];
    }

    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: 'begin_checkout',
      status: 'logged_in',
      ecommerce: {
        value: data.value,
        currency: data.currency,
        items: [
          {
            index: 0,
            item_id: data.name,
            item_name: data.name,
            item_category: 'Subscription',
            item_category2: 'CustomAccess',
            value: data.value,
            currency: data.currency,
            access_days: data.access_days,
            is_wellness: true,
            quantity: 1,
          },
        ],
      },
    });
    // window.dataLayer?.push({
    //   ...data,
    //   event: 'purchase_custom_access_begin',
    // });
  }

  purchase_CustomAccessComplete(data: {
    order_id: string;
    name: string;
    value: number;
    original_amount: number;
    currency: string;
    access_days: number;
    payment_type: string;
    wellness: boolean;
    new_customer: boolean;
  }) {
    this.sendEvent('purchase_custom_access_complete', data);
    if (!window.dataLayer) {
      window.dataLayer = [];
    }
    window.dataLayer.push({ ecommerce: null });
    // @ts-ignore
    window.dataLayer?.push({
      event: 'purchase',
      new_customer: data.new_customer,
      status: this.currentUser ? 'logged_in' : 'logged_out',
      ecommerce: {
        transaction_id: data.order_id,
        value: data.value,
        currency: data.currency,
        items: [
          {
            index: 0,
            item_id: data.name,
            item_name: data.name,
            item_category: 'Subscription',
            item_category2: 'CustomAccess',
            value: data.value,
            currency: data.currency,
            quantity: 1,
            access_days: data.access_days,
            is_wellness: data.wellness,
          },
        ],
      },
    });
  }
  purchase_CustomAccessFail(data) {
    this.sendEvent('purchase_custom_access_fail', data);
  }

  // Wellness access
  purchase_WellnessAccessBegin(data: {
    name: string;
    value: number;
    currency: string;
    new_customer: boolean;
  }) {
    this.sendEvent('purchase_wellness_access_begin', data);
    if (!window.dataLayer) {
      window.dataLayer = [];
    }
    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: 'begin_checkout',
      status: 'logged_in',
      ecommerce: {
        value: data.value,
        currency: data.currency,
        items: [
          {
            index: 0,
            item_id: data.name,
            item_name: data.name,
            item_category: 'Subscription',
            item_category2: 'WellnessAccess',
            value: data.value,
            currency: data.currency,
            quantity: 1,
          },
        ],
      },
    });
  }
  purchase_WellnessAccessComplete(data) {
    this.sendEvent('purchase_wellness_access_complete', data);
    window.dataLayer &&
      window.dataLayer?.push({
        ...data,
        event: 'purchase_wellness_access_complete',
      });
  }
  purchase_WellnessAccessFail(data) {
    this.sendEvent('purchase_wellness_access_fail', data);
  }

  // LivePackage
  purchase_LivePackageBegin(data) {
    this.sendEvent('purchase_live_package_begin', data);
    window.dataLayer &&
      window.dataLayer?.push({
        ...data,
        event: 'purchase_live_package_begin',
      });
  }
  purchase_LivePackageComplete(data: {
    order_id: string;
    name: string;
    value: number;
    original_amount: number;
    currency: string;
    payment_type: string;
    wellness: boolean;
    new_customer: boolean;
  }) {
    this.sendEvent('purchase_live_package_complete', data);
    if (!window.dataLayer) {
      window.dataLayer = [];
    }
    window.dataLayer.push({ ecommerce: null });
    // @ts-ignore
    window.dataLayer?.push({
      event: 'purchase',
      new_customer: data.new_customer,
      status: this.currentUser ? 'logged_in' : 'logged_out',
      ecommerce: {
        transaction_id: data.order_id,
        value: data.value,
        currency: data.currency,
        items: [
          {
            index: 0,
            item_id: data.name,
            item_name: data.name,
            item_category: 'LiveEvent',
            item_category2: 'LivePackage',
            value: data.value,
            currency: data.currency,
            quantity: 1,
            is_wellness: true,
          },
        ],
      },
    });
  }
  purchase_LivePackageFail(data) {
    this.sendEvent('purchase_live_package_fail', data);
  }

  // Bundle
  purchase_bundleBegin(data) {
    this.sendEvent('purchase_bundle_begin', data);
    if (!window.dataLayer) {
      window.dataLayer = [];
    }

    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: 'begin_checkout',
      status: 'logged_in',
      ecommerce: {
        value: data.value,
        currency: data.currency,
        items: [
          {
            index: 0,
            item_id: data.slug,
            item_name: data.title,
            item_category: 'bundle',
            value: data.value,
            currency: data.currency,
            quantity: 1,
          },
        ],
      },
    });
  }

  purchase_bundleComplete(data: {
    order_id: string;
    name: string;
    id: string;
    value: number;
    original_amount: number;
    currency: string;
    payment_type: string;
    sourcePage: string;
    new_customer: boolean;
  }) {
    this.sendEvent('purchase_bundle_complete', data);
    if (!window.dataLayer) {
      window.dataLayer = [];
    }
    window.dataLayer.push({ ecommerce: null });
    // @ts-ignore
    window.dataLayer?.push({
      event: 'purchase',
      new_customer: data.new_customer,
      status: this.currentUser ? 'logged_in' : 'logged_out',
      ecommerce: {
        transaction_id: data.order_id,
        value: data.value,
        currency: data.currency,
        items: [
          {
            index: 0,
            item_id: data.id,
            item_name: data.name,
            item_category: 'Bundle',
            value: data.value,
            currency: data.currency,
            quantity: 1,
          },
        ],
      },
    });
  }

  purchase_bundleFail(data) {
    this.sendEvent('purchase_bundle_fail', data);
  }

  // subscription cancelation
  subscription_cancel(data) {
    this.sendEvent('subscription_cancel', data);
    window.dataLayer &&
      window.dataLayer?.push({
        ...data,
        event: 'subscription_cancel',
      });
  }

  //
  item_view(data: {
    category: 'Subscription' | 'LiveEvent' | 'Course' | 'Bundle';
    slug: string;
    title: string;
    price: number;
    currency: string;
    new_customer: boolean;
  }) {
    if (!window.dataLayer) {
      window.dataLayer = [];
    }

    window.dataLayer.push({ ecommerce: null });
    window.dataLayer.push({
      event: 'view_item',
      status: this.currentUser ? 'logged_in' : 'logged_out',
      new_customer: data.new_customer,
      ecommerce: {
        value: data.price,
        currency: data.currency,
        items: [
          {
            item_id: data.slug,
            item_name: data.title,
            item_category: data.category,
            value: data.price,
            currency: data.currency,
            quantity: 1,
          },
        ],
      },
    });
  }

  sendEvent(event, data = {}) {
    try {
      // for GA4, send user data on all events
      // window.gtag?.({
      //   event,
      //   key: event,
      //   // ...this.currentUser,
      //   ...data,
      // });
    } catch (ex) {
      //
    }
  }

  // https://developers.google.com/analytics/devguides/collection/gtagjs/events
  event = (params: EventParams) => {
    const { action, category, label, value, currency } = params;
    try {
      window.gtag?.('event', action, {
        event_category: category,
        event_label: label,
        value,
        currency,
        send_to: GA_TRACKING_ID,
      });
    } catch (ex) {
      //
    }
  };
}

export default GoogleAnalyticsManager;
