import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { useRouter } from 'next/router';
import { t } from '@lingui/macro';
import { Trans } from '@lingui/react';
import { Box, Flex } from '@components/layout/Grid';
import LanguageContext from '@lib/contexts/languageContext';
import ForgotPasswordModal from '@organisms/modals/ForgotPasswordModal';
import SocialAuth from '@ecosystems/socialAuth';
import {
  MethodTypes,
  ProviderTypes,
  useAuthenticateUserMutation,
  useSocialSignInMutation,
} from '@gql/generated';
import { P } from '@components/typography/Paragraph';
import { H2 } from '@components/typography/Headings';
import AnalyticsManager from '@lib/analytics/manager';
import { Button } from '@components/ButtonV2';
import PasswordInput from '@components/form/PasswordInput';
import Input from '@components/form/Input2';

const Container = styled(Flex)`
  flex: 1;
  justify-content: center;
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
`;

const LoginButton = styled(Button)`
  width: 100%;
  justify-content: center;
`;

export const Title = styled(H2)`
  font-weight: 600;
`;

const SignUp = styled.p`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.neutrals['black']};
  font-weight: 600;
  text-align: center;

  :first-letter {
    text-transform: capitalize;
  }

  a {
    font-size: inherit;
    font-weight: inherit;
  }
`;

const ErrorText = styled.span`
  color: ${({ theme }) => theme.colors.secondary['red-1']};
  font-size: 13px;
  text-align: center;
`;

type Props = {
  className?: string;
  title?: React.ReactNode;
  description?: React.ReactNode;
  noRegister?: boolean;
  registerUrlParams?: Record<string, any>;
  callBack?(): void;
};

const LoginForm = (props: Props) => {
  const { noRegister } = props;
  const formRef = useRef<HTMLFormElement>();
  const router = useRouter();
  const [loading, setLoading] = useState(false);
  const lang = useContext(LanguageContext);
  const [authenticateUser, { error }] = useAuthenticateUserMutation();

  useEffect(() => {
    formRef.current?.email.focus();
  }, []);

  const registerUrl = useMemo(() => {
    const params = new URLSearchParams();

    const to = router.query.to ?? router.query.next;
    const redirect_to = router.query.redirect_to;

    if (to) {
      params.set('to', to as string);
    } else if (redirect_to) {
      params.set('redirect_to', redirect_to as string);
    }

    if (props.registerUrlParams) {
      Object.entries(props.registerUrlParams).forEach(([key, val]) => {
        params.set(key, val);
      });
    }
    const query = params.toString() ? '?' + params.toString() : '';
    return `/${lang}/register/account` + query;
  }, []);

  const handleLogin = (userId) => {
    AnalyticsManager().login({ user_id: userId });
    if (props.callBack && typeof props.callBack === 'function') {
      props.callBack();
    } else {
      const to = router.query.to ?? router.query.next;
      const redirect_to = router.query.redirect_to;
      if (props.registerUrlParams?.to) {
        window.location.href = decodeURIComponent(props.registerUrlParams?.to);
      } else if (to) {
        window.location.href = decodeURIComponent(to as string);
      } else if (redirect_to) {
        window.location.href = decodeURIComponent(redirect_to as string);
      } else {
        window.location.reload();
      }
    }
  };

  const [
    socialSignIn,
    {
      data: socialAuthData,
      loading: socialAuthLoading,
      error: socialAuthErrors,
    },
  ] = useSocialSignInMutation({
    onCompleted(resp) {
      if (resp.socialSignIn.user.id) {
        handleLogin(resp.socialSignIn.user?.id);
      }
    },
  });

  const handleSocialSignIn = async (token, provider) => {
    try {
      await socialSignIn({
        variables: {
          method: MethodTypes['Token'],
          provider,
          accessToken: token,
          identityToken: '',
          code: '',
        },
      });
    } catch (ex) {
      //  such error
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const email = event?.target?.email?.value;
      const password = event?.target?.password?.value;
      //
      const { data } = await authenticateUser({
        variables: { email, password },
      });

      // for some reason this promise never resolves or rejects
      setLoading(false);

      //
      if (!data?.authenticateUser?.user?.id) {
        return null;
      }

      handleLogin(data?.authenticateUser?.user?.id);
    } catch (e) {
      setLoading(false);
    }
  };

  return (
    <Container className={props.className} flexDirection="column" mx="auto">
      <Title>
        {props?.title || <Trans id="login_modal.title" message="Log In" />}
      </Title>
      <Box mt="0px" mb="20px" as={P} bold>
        {props.description || (
          <Trans
            id="login_modal.helper"
            message={
              'Logga in för åtkomst till videobiblioteket, vårt växande kursutbud online och din profil.'
            }
          />
        )}
      </Box>
      <form
        method="post"
        title="Login form"
        ref={formRef}
        onSubmit={handleSubmit}
      >
        <div className="mb-5">
          <Input
            type="email"
            name="email"
            className="placeholder:text-sm"
            placeholder={t`login_modal.inputplaceholder.email`}
            data-testid="LoginModal--EmailInput"
          />
          <PasswordInput
            className="placeholder:text-sm"
            type="password"
            name="password"
            placeholder={t`login_modal.inputplaceholder.password`}
            data-testid="LoginModal--PasswordInput"
          />
        </div>
        {error ? (
          <Box mb="30px">
            <ErrorText
              dangerouslySetInnerHTML={{
                __html: t({ id: 'login_modal.errors.incorrect' }),
              }}
            />
          </Box>
        ) : null}
        <Flex justifyContent="stretch" mb="10px">
          <LoginButton
            type="submit"
            appearance="default-blue"
            loading={loading || socialAuthLoading}
            data-testid="LoginModal--LoginButton"
          >
            <Trans id="login_modal.action.login" message="LOG IN" />
          </LoginButton>
        </Flex>
      </form>
      <Flex justifyContent="center" mb={30}>
        <ForgotPasswordModal>
          {(handleOpenModal) => (
            <Button
              appearance="dark"
              link={true}
              onClick={handleOpenModal}
              data-testid="LoginModal--ForgotPasswordButton"
            >
              <Trans
                id="login_modal.action.forgot"
                message="Forgot Password?"
              />
            </Button>
          )}
        </ForgotPasswordModal>
      </Flex>
      <SocialAuth
        title={t({ id: 'login_modal.action.social_label' })}
        onGoogleAuth={(token) => {
          handleSocialSignIn(token, ProviderTypes['Google']);
        }}
        onFacebookAuth={(token) => {
          handleSocialSignIn(token, ProviderTypes['Facebook']);
        }}
        onAppleAuth={(token) => {
          handleSocialSignIn(token, ProviderTypes['Apple']);
        }}
      />
      {socialAuthErrors?.graphQLErrors?.length > 0 ||
      socialAuthData?.socialSignIn?.success === false ? (
        <Flex justifyContent="center" alignItems="center" mt="10px" mb="10px">
          <ErrorText
            dangerouslySetInnerHTML={{
              __html: t({ id: 'login_modal.errors.incorrect_socials' }),
            }}
          />
        </Flex>
      ) : null}
      {!noRegister ? (
        <Box pb={[20, 20, 30]}>
          <SignUp>
            <Trans
              id="login_modal.action.or_create"
              components={[
                <Button key="0" href={registerUrl} appearance="blue" link>
                  {null}
                </Button>,
              ]}
            />
          </SignUp>
        </Box>
      ) : null}
    </Container>
  );
};

export default LoginForm;
