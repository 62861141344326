import gql from 'graphql-tag';
import {
  ApolloLink,
  ApolloClient,
  NormalizedCacheObject,
  InMemoryCache,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';
import fetch from 'isomorphic-unfetch';
import Router from 'next/router';
import * as Sentry from '@sentry/nextjs';
import { AUTH_TOKEN, AUTH_REFRESH_TOKEN } from '@lib/constants';
import { getMemoryCache, resetReactiveVars } from '@lib/apollo/policies';
import { usePreference } from '@lib/hooks/usePreference';
import { buildFlags, names as FLAGS_NAMES } from '@lib/hooks/useFlags';
import { FlagsDocument, FlagsQuery } from '@gql/generated';
export { videoSettingsModalOpenVar, videoSettingsVar } from './policies';

// Log any GraphQL errors or network error that occurred
const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, path }) => {
      Sentry.captureEvent({
        message: `[Graphql error] ${message}`,
        contexts: {
          operation: {
            path,
            operationName: operation.operationName,
            variables: JSON.stringify(operation.variables),
          },
        },
        // adding tags to make filtering easy on sentry
        tags: {
          operationName: operation.operationName,
        },
      });
    });
  if (networkError) {
    Sentry.captureException({
      message: `[Graphql network error] ${networkError.message}`,
      contexts: {
        message: networkError.message,
        operationName: operation.operationName,
        variables: JSON.stringify(operation.variables),
        statusCode: networkError['statusCode'],
      },
      // adding tags to make filtering easy on sentry
      tags: {
        operationName: operation.operationName,
      },
    });
  }
});

export const GET_VIDEO_SETTINGS = gql`
  query videoSettings {
    videoSettings @client {
      excludeTerms
      contentLanguages
      videoLevels
    }
  }
`;

export let apolloClient: ApolloClient<NormalizedCacheObject> = null;

/**
 * Creates and configures the ApolloClient
 * @param  {Object} [initialState={}]
 */

export function createApolloClient({
  initialState,
  lang,
  currency,
  cookies = null,
  api_url = null,
}) {
  const VERCEL_URL = process.env.VERCEL_URL
    ? `https://${process.env.VERCEL_URL}`
    : 'http://localhost:3000';

  const ENABLE_API = !['master', 'develop'].includes(
    process.env.VERCEL_GIT_COMMIT_REF
  );

  let API_URL = api_url;
  if (!api_url && ENABLE_API && !process.env.browser) {
    API_URL = VERCEL_URL + '/api';
  } else {
    API_URL = process.env.APOLLO_BACKEND_RAILS;
  }

  const simpleHttpLink = new createUploadLink({
    uri: API_URL,
    credentials: 'same-origin',
    fetch,
  });

  const headersEnhancer = new ApolloLink((operation, forward) => {
    operation.setContext(({ headers, cache }) => {
      let language = lang;
      if (typeof window !== 'undefined') {
        language = Router.query.lang;
      }

      // we send currency from payment mutations to avoid any async issues...
      let _currency = headers?.currency;
      if (!_currency) {
        _currency =
          (typeof window === 'undefined'
            ? currency
            : usePreference.getState().currency) || 'SEK';
      }

      const flags = (cache as InMemoryCache).readQuery<FlagsQuery>({
        query: FlagsDocument,
        variables: {
          names: FLAGS_NAMES,
        },
      });

      const disable_currencies = buildFlags(
        flags?.flipperFlags || []
      ).disable_currencies;

      return {
        headers: {
          ...headers,
          'Accept-Language': language || 'se',
          'X-User-Currency': disable_currencies
            ? ['se', 'fi'].includes(lang)
              ? 'SEK'
              : 'EUR'
            : _currency,
          // on ssr we read cookies from request & pass them to proxy request
          // on client they will be handled by proxy directly
          ...(typeof window === 'undefined' && cookies?.refresh_token
            ? {
                Cookie: `${AUTH_TOKEN}=${cookies[AUTH_TOKEN]};${AUTH_REFRESH_TOKEN}=${cookies[AUTH_REFRESH_TOKEN]};`,
              }
            : {}),
        },
      };
    });

    return forward(operation).map((data) => {
      const UNAUTH_MESSAGE = 'You need to authenticate to perform this action';
      if (data?.errors?.[0]?.message === UNAUTH_MESSAGE) {
        Router.replace({
          pathname: `/${lang}/login`,
          query: {
            to: Router.asPath,
          },
        });
      }
      return data;
    });
  });

  const httpEnahncedLink = errorLink.concat(
    headersEnhancer.concat(simpleHttpLink)
  );

  let ssrMode = false;
  if (typeof window === 'undefined') {
    ssrMode = true;
  }

  const client = new ApolloClient({
    ssrMode, // Disables forceFetch on the server (so queries are only run once)
    link: httpEnahncedLink,
    cache: getMemoryCache({ initialState }, ssrMode),
    connectToDevTools: process.env.NODE_ENV === 'development',
  });

  client.onResetStore(() => {
    return Promise.resolve(resetReactiveVars());
  });

  return client;
}

/**
 * Always creates a new apollo client on the server
 * Creates or reuses apollo client in the browser.
 * @param  {Object} initialState
 */

type Params = {
  lang: string;
  currency: string;
  cookies?: { token?: string; refresh_token?: string };
  api_url?: string;
  initialState: Record<any, any>;
};
export function initApolloClient(params: Params) {
  // Make sure to create a new client for every server-side request so that data
  // isn't shared between connections (which would be bad)
  if (typeof window === 'undefined') {
    return createApolloClient(params);
  }

  // Reuse client on the client-side
  if (!apolloClient) {
    apolloClient = createApolloClient(params);
  }

  return apolloClient;
}
