import {
  FunnelKind,
  RegistrationStepEnum,
  useRegistrationStepMutation,
} from '@gql/generated';

const STEPS = {
  registration_visit: RegistrationStepEnum['RegistrationVisit'],
  registration: RegistrationStepEnum['registration'],
  play_visit: RegistrationStepEnum['Play_2'],
  play_trial_visit: RegistrationStepEnum['Play_3A'],
  play_trial_payment_method_visit: RegistrationStepEnum['Play_3A_1'],
  play_trial_payment_method_added: RegistrationStepEnum['Play_3A_2'],
  play_trial_profile_completed: RegistrationStepEnum['Play_3A_3'],
  play_trial_email_verified: RegistrationStepEnum['Play_3A_4'],
  play_subscription_visit: RegistrationStepEnum['Play_3B'],
  play_subscription_payment_method_visit: RegistrationStepEnum['Play_3B_1'],
  play_subscription_payment_method_added: RegistrationStepEnum['Play_3B_2'],
  play_subscription_profile_completed: RegistrationStepEnum['Play_3B_3'],
  play_subscription_email_verified: RegistrationStepEnum['Play_3B_4'],
  play_wellness_selected: RegistrationStepEnum['Play_3C'],
  play_wellness_profile_completed: RegistrationStepEnum['Play_3C_3'],
  play_wellness_email_verified: RegistrationStepEnum['Play_3C_4'],
  play_other: RegistrationStepEnum['Other'],
} as const;

export const useTrackRegistration = () => {
  const [registrationStep] = useRegistrationStepMutation();

  const trackStep = (step: keyof typeof STEPS) => {
    registrationStep({
      variables: {
        step: STEPS[step],
        funnel: FunnelKind['Play'],
      },
    });
  };

  return trackStep;
};
