import styled, { useTheme } from 'styled-components';
import { Button as button } from '@components/ButtonV2';
import banner from '@components/MessageStripe';

const Banner = styled(banner)`
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;

  p {
    width: 100%;
    text-align: center;
  }
`;
const Button = styled(button)`
  svg {
    margin-right: 5px;
  }
`;

const Icon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      className="lucide lucide-monitor-x"
    >
      <path d="m14.5 12.5-5-5" />
      <path d="m9.5 12.5 5-5" />
      <rect width="20" height="14" x="2" y="3" rx="2" />
      <path d="M12 17v4" />
      <path d="M8 21h8" />
    </svg>
  );
};

export const EndImpersonatorSession = () => {
  const theme = useTheme();
  return (
    <Banner bg={theme.colors.secondary['red-1']} closable={false}>
      <Button
        appearance="white"
        href="/admin/users/end_impersonation"
        onClick={() => {
          window.location.href = '/admin/users/end_impersonation';
        }}
        link
      >
        <Icon />
        End Impersonation session
      </Button>
    </Banner>
  );
};
