import React, { useState } from 'react';
import Styled from 'styled-components';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import NewInput from './NewInput';

const Wrapper = Styled.div`
  position: relative;
  display: flex;
`;

const Input = Styled(NewInput)`
  margin-top: 10px;
`;

const Button = Styled.button`
  position: absolute;
  top: calc(50% + 5px);
  right: 20px;
  display: flex;
  align-items: center;
  font-size: 24px;
  color: #0000008a;
  border: none;
  margin: 0;
  background: none;
  transform: translateY(-50%);
  cursor: pointer;
  outline: none;
`;

declare type Props = {
  type?: 'text' | 'password';
} & React.InputHTMLAttributes<HTMLInputElement>;

const PasswordInput = (props: Props) => {
  const { type, ...otherProps } = props;
  const initialInputType = type || 'password';
  const [inputType, setInputtype] = useState(initialInputType);
  const Icon = inputType === 'password' ? Visibility : VisibilityOff;

  return (
    <Wrapper>
      <Input
        {...otherProps}
        type={inputType}
        data-testid={props['data-testid'] || 'PasswordInput'}
      />
      <Button
        data-testid="PasswordInput--ToggleButton"
        type="button"
        onClick={e => {
          e.stopPropagation();
          const nextType = inputType === 'text' ? 'password' : 'text';
          setInputtype(nextType);
        }}
      >
        <Icon fontSize="inherit" color="inherit" />
      </Button>
    </Wrapper>
  );
};

export default PasswordInput;
