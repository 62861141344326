import React, { useContext } from 'react';
import LanguageContext from '@lib/contexts/languageContext';
import { Trans } from '@lingui/react';
import styled from 'styled-components';
import { Container } from '@components/layout/Grid';
import footerLinks from '@lib/footerLinks';
import { ANDROID_APP_LINK, IOS_APP_LINK } from '@lib/constants';
import {
  CurrencyButton,
  LanguageDropdown,
} from './modals/CurrencyLanguageModal';
import useFlags from '@lib/hooks/useFlags';
import Icon from '@components/Icon';
import { cn } from '@lib/tw';

const Wrapper = styled.footer`
  color: white;
  border: 1px solid transparent;
  background-color: ${(props) => props.theme.colors.secondary['gray-5']};
`;

const MobileStoreImage = styled.img`
  object-fit: contain;
`;

const Divider = () => {
  return (
    <div className="py-7">
      <div className="h-[1px] bg-white/10" />
    </div>
  );
};

const Footer = () => {
  const locale = useContext(LanguageContext);
  const [flags] = useFlags();

  return (
    <Wrapper className="px-4">
      <Container flexDirection="column" my={[0, 20]} pt={20}>
        <div className="w-full flex flex-col md:flex-row md:items-center gap-5 flex-wrap pb-2">
          <div className="flex flex-wrap gap-4 flex-shrink-0 flex-1 items-center">
            <div className="flex-1 flex items-center gap-2">
              {flags?.disable_currencies ? (
                <p className="pr-2">
                  <Trans id="footer.languages.label" />:
                </p>
              ) : null}
              <LanguageDropdown />
              <CurrencyButton />
            </div>
            <div className="flex gap-1">
              <a
                className="flex items-center"
                href={ANDROID_APP_LINK}
                target="_blank"
                rel="noreferrer noopener"
              >
                <MobileStoreImage
                  alt="playstore icon"
                  src={
                    locale === 'se'
                      ? '/static/google-play-badge-SE.png'
                      : '/static/google-play-badge-en.png'
                  }
                  width="100px"
                  height="30px"
                  loading="lazy"
                />
              </a>
              <a
                href={IOS_APP_LINK}
                className="flex items-center"
                target="_blank"
                rel="noreferrer noopener"
              >
                <MobileStoreImage
                  alt="appstore icon"
                  src={
                    locale === 'se'
                      ? '/static/App_Store_Badge_se.svg'
                      : '/static/App_Store_Badge_en.svg'
                  }
                  width="100px"
                  height="30px"
                  loading="lazy"
                />
              </a>
            </div>
          </div>
        </div>
        <Divider />
        <div className="flex flex-wrap gap-y-10">
          {footerLinks[['se', 'no'].includes(locale) ? 'se' : 'en'].map(
            ({ title, items }, index, arr) => {
              return (
                <div
                  key={index}
                  className={cn(
                    'w-full xs:w-1/2 md:w-1/3',
                    arr.length > 3 ? 'lg:w-1/5' : ''
                  )}
                >
                  <p className="text-base m-0 pb-2.5 font-semibold">{title}</p>
                  <ul className="list-none flex flex-col gap-4 p-0">
                    {items.map((item, index) => {
                      return (
                        <li
                          key={index}
                          className="text-white text-sm font-normal"
                        >
                          <a
                            href={Object.values(item)[0] as string}
                            className="text-inherit"
                          >
                            {Object.keys(item)[0]}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              );
            }
          )}
        </div>
      </Container>
      <Divider />
      <Container pb={20} mt={[0, 0, -10]}>
        <div className="w-full flex flex-col md:flex-row justify-between items-center">
          <Icon
            className="!before:text-white"
            name="ygb-icon-yogobe-logo2019_1"
            fontSize="24px"
            color="#fff"
          />
          <p className="text-xs text-center">
            <Trans
              id="footer.copyright"
              message={'©YOGOBE 2020. All rights reserved.'}
              values={{ year: new Date().getFullYear() }}
            />
          </p>
        </div>
      </Container>
    </Wrapper>
  );
};

export default Footer;
