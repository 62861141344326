export default {
  en: [
    {
      title: 'Play',
      items: [
        { 'Get started with Play': '/en/videos/overview' },
        { 'Audio & video library': '/en/videos/genres/all' },
        { 'LIVE classes': '/en/live?kind=classe' },
        { Programs: '/en/programs' },
        { Challenges: '/en/challenges' },
        { Playlists: '/en/playlists' },
      ],
    },
    {
      title: 'Social media',
      items: [
        { Facebook: 'https://www.facebook.com/YOGOBEcom/' },
        { Instagram: 'https://www.instagram.com/yogobe' },
        { Youtube: 'https://www.youtube.com/user/YogobeOfficial' },
        { Linkedin: 'https://www.linkedin.com/company/yogobe-ab' },
        {
          'Facebook group (SE)':
            'https://www.facebook.com/groups/354104138026166',
        },
      ],
    },
    {
      title: 'Discover',
      items: [
        {
          'Prison Yoga Project':
            '/en/videos/genres/all?yoga-workout=prison-yoga',
        },
        { 'Trauma program': '/en/programs/trauma-informed-yoga/overview' },
        {
          'Meditation program': '/en/programs/meditation-for-life-en/overview',
        },
        { 'Refugee Project': '/en/integration-yoga' },
      ],
    },
    {
      title: 'Our service',
      items: [
        { Pricing: '/en/pricing' },
        { 'Wellness contribution': '/en/friskvardsbidrag' },
        { 'Contact us': '/se/kontakt' },
        { FAQ: '/se/faq' },
        { 'About Yogobe': '/se/om-oss' },
        { Shop: 'https://store.yogobe.com/' },
        { Blog: '/en/blog' },
        { 'For Employers': '/en/business' },
        { 'Terms & conditions': '/en/terms-and-conditions' },
        { 'Privacy Policy': '/en/privacy-policy' },
      ],
    },
  ],
  se: [
    {
      title: 'Play',
      items: [
        { 'Kom igång med Play': '/se/videos/overview' },
        { 'Audio & videobiblioteket': '/se/videos/genres/all' },
        { 'LIVE-klasser': '/se/live?kind=classe' },
        { Program: '/se/programs' },
        { Utmaningar: '/se/challenges' },
        { 'Populära ämnen inom hälsa': '/se/needs-and-themes' },
        { Playlists: '/se/playlists' },
      ],
    },
    {
      title: 'Upptäck',
      items: [
        { Yogapositioner: '/se/yoga/positioner' },
        { Yogaformer: '/se/yoga/stilar' },
        { Meditationsformer: '/se/meditation/om-meditation' },
        { Träningsformer: '/se/movement' },
      ],
    },
    {
      title: 'Populärt innehåll',
      items: [
        { Klimakteriet: '/se/c/yogobe/b/klimakteriet' },
        { 'Yoga Nidra': '/se/c/yogobe/b/yoganidra' },
        { Sömn: '/se/c/yogobe/b/somn' },
        { 'Rehab & prehab': '/se/c/yogobe/b/rehabprehab' },
        { Stresshantering: '/se/c/yogobe/b/stress-och-oro' },
        { Kvinnohälsa: '/se/c/yogobe/b/kvinnohalsa' },
        { Yinyoga: '/se/c/yogobe/b/yinyoga' },
        { Yoga: '/se/c/yogobe/b/yoga' },
        { Mindfulness: '/se/c/yogobe/b/meditation' },
        { Återhämtning: '/se/c/yogobe/b/aterhamtning' },
        { Pilates: '/se/c/yogobe/b/pilates' },
        { 'Gravid & Postnatal': '/se/c/yogobe/b/gravid' },
        { Senior: '/se/c/yogobe/b/seniorer' },
        { 'Morgon & kvällsrutiner': '/se/c/yogobe/b/rutiner' },
      ],
    },
    {
      title: 'Sociala medier',
      items: [
        { Facebook: 'https://www.facebook.com/YOGOBEcom/' },
        { Instagram: 'https://www.instagram.com/yogobe' },
        { Youtube: 'https://www.youtube.com/user/YogobeOfficial' },
        { Linkedin: 'https://www.linkedin.com/company/yogobe-ab' },
        { 'FB-grupp': 'https://www.facebook.com/groups/354104138026166' },
      ],
    },
    {
      title: 'Tjänsten',
      items: [
        { 'Priser & tjänster': '/se/pricing' },
        { Friskvårdsbidrag: '/se/friskvardsbidrag' },
        { 'Kontakta oss': '/se/kontakt' },
        { 'Frågor & svar': '/se/faq' },
        { 'Om Yogobe': '/se/om-oss' },
        { Shop: 'https://store.yogobe.com/' },
        { 'Team Yogobe': '/se/team' },
        { Blogg: '/se/blog' },
        { Arbetsgivare: 'https://arbetsgivare.yogobe.com/' },
        { FaR: '/se/c/far/b/far' },
        {
          Skola:
            '/se/blog/skola-digital-halsa#:~:text=Vi%20erbjuder%20skolor%20och%20pedagoger,inom%20h%C3%A4lsa%20och%20%C3%B6kad%20m%C3%A5luppfyllelse.',
        },
        { Sjukvården: '/se/healthcare' },
        { Press: '/se/press' },
        { Integritetspolicy: '/se/integritetspolicy' },
        { Villkor: '/se/villkor' },
      ],
    },
  ],
};
