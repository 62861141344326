import { useMemo } from 'react';
import { ApolloError } from '@apollo/client';
import { useFlagsQuery } from '@gql/generated';

export const names = [
  'payments_adyen',
  'yearly_campaign',
  'disable_trustly',
  'disable_saved_trustly_payments',
  'disable_trustly_add_payment_method',
  'disable_hls',
  'google_oauth_feature',
  'search_disabled',
  'video_stats',
  'disable_dashboard_stats',
  'wellness',
  'yearly_campaign_banners',
  'disable_fb_auth',
  'enable_manual_trial',
  'disable_email_preferences',
  'disable_instructor_profile',
  'disable_trial_upsell_offers',
  'disable_epassi',
  'enable_free_trial',
  'disable_cross_promotion',
  'disable_yogobe_access_top_up',
  'free_live_verification_required',
  'enable_epassi_pending_modal',
  'enable_cookiebot',
  'register_subscription_v2',
  'trustly_iframe',
  'six_months_campaign',
  'sommar99_campaign',
  'mindfulnesskalendern',
  'quarterly_campaign',
  'disable_currencies',
  'enable_native_hls',
  '6mc_code',
  'disable_live_recording',
  'apoteket_campaign',
  'B2B_registeration',
] as const;

export type AvailableFlags = { [k in (typeof names)[number]]: boolean };

export function buildFlags(flags): AvailableFlags {
  return (
    flags?.reduce((acc, flag) => {
      acc[flag.name] = flag.value;
      return acc;
    }, {}) || {}
  );
}

const useFlags = (): [
  AvailableFlags,
  { loading: boolean; error: ApolloError },
] => {
  const { data, loading, error } = useFlagsQuery({
    variables: {
      names: Array.from(names),
    },
  });

  const flags: AvailableFlags = useMemo(() => {
    const flags = buildFlags(data?.flipperFlags);
    return flags;
  }, [data?.flipperFlags]);

  return [flags, { loading, error }];
};

export default useFlags;
